import { observer } from "mobx-react";
import { useURI } from "ms_router";
import { useEffect, useState } from "react";
import { get_ref_cd } from "src/repository/Member/myinfo";
import { InviteLoginTitle } from "../Components/InviteLoginTitle";
import { InviteNonLoginTitle } from "../Components/InviteNonLoginTitle";
import { spacing } from "ms-ui";

export const TitleTpl = observer(() => {
  const { getQuery } = useURI();
  const q = getQuery<{ rcode?: string; refCd?: string }>();
  const [info, setInfo] = useState<{ nick: string; refCd: string }>();

  const loadNick = async () => {
    try {
      const refCd = q.refCd ?? q.rcode;
      if (!refCd) return;
      const res = await get_ref_cd({ refCd }).req();
      if (res.data) {
        setInfo(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(() => {
    loadNick();
  }, []);

  return (
    <div style={{ paddingTop: spacing[64].value }}>
      {info && info?.nick !== undefined ? (
        <InviteLoginTitle userNm={info.nick} />
      ) : (
        <InviteNonLoginTitle />
      )}
    </div>
  );
});
