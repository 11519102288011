import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Button } from "ms-ui";
import styled from "styled-components";

export interface IButton {
  onClickHandler: () => void;
}

export const MainButton = observer(({ onClickHandler }: IButton) => {
  return (
    <>
      <StDiv1>
        <Button
          color={`primary`}
          size={`rg`}
          radius={`5`}
          outline={false}
          onClick={onClickHandler}
          width={`100%`}
        >
          꿀잠 출근라이프 시작하기
        </Button>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[32].value} ${spacing[16].value};
  flex-direction: column;
  background: ${contents.secondary.value};
`;
