import { observer } from "mobx-react";
import { MBus } from "../../Components/MBus";
import { BottomFixed, spacing } from "ms-ui";
import styled from "styled-components";
import { useURI } from "ms_router";
import { MainButton } from "./MainButton";

export const BottomTpl = observer(() => {
  const { navigate } = useURI();

  return (
    <BottomFixed>
      <MBus />
      <MainButton onClickHandler={() => navigate("/")} />
    </BottomFixed>
  );
});

const ButtonContainer = styled.div`
  padding: ${spacing[32].value} ${spacing[16].value};
`;
