import { observer } from "mobx-react";
import { Nav } from "ms-ui";
import { useURI } from "ms_router";
import { Logo } from "src/pages/Main/views/MainSection/logo";
import { TitleTpl } from "./TitleTpl";
import { InviteBubble } from "./InviteBubble";
import { BottomTpl } from "./BottomTpl";
import { StContainer } from "../Components/Style";

const InviteMain = observer(() => {
  const { navigate } = useURI();

  return (
    <>
      <Nav
        left={{
          type: "custom",
          onClick: () => navigate("/"),
          svg: <Logo />,
        }}
      />

      <StContainer>
        <div style={{ paddingBottom: "231px" }}>
          <TitleTpl />
          <InviteBubble />
        </div>

        <BottomTpl />
      </StContainer>
    </>
  );
});

export default InviteMain;
