import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, borderRadius, Body1 } from "ms-ui";
import styled from "styled-components";

export const InviteBubble = observer(() => {
  return (
    <>
      <StDiv1>
        <StDiv2>
          <StDiv3>
            <StDiv4>
              왜 지옥철 타? 왜 만원 버스 타?
              <br />
              모두의셔틀 타봐~ 삶의 질 수직 상승!
            </StDiv4>
          </StDiv3>
        </StDiv2>
        <StDiv5>
          <StDiv6>
            <StDiv7>
              내 추천인 코드로 친구가 탑승하면 <br />
              친구와 추천인 모두에게 혜택을!!
            </StDiv7>
          </StDiv6>
        </StDiv5>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px 24px 24px;
  flex-direction: column;
  gap: ${spacing[24].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  padding: ${spacing[16].value} ${spacing[20].value};
  flex-direction: column;
  border-radius: ${borderRadius[10].value};
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
  width: fit-content;
`;

const StDiv4 = styled.div`
  ${Body1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StDiv6 = styled.div`
  display: flex;
  padding: ${spacing[16].value} ${spacing[20].value};
  justify-content: flex-end;
  border-radius: ${borderRadius[10].value};
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
  width: fit-content;
`;

const StDiv7 = styled.div`
  ${Body1};
  color: ${contents.subtitle.value};
  width: fit-content;
`;
